export const ariaPropNames = {
  'aria-controls': true,
  'aria-describedby': true,
  'aria-details': true,
  'aria-errormessage': true,
  'aria-expanded': true,
  'aria-haspopup': true,
  'aria-hidden': true,
  'aria-invalid': true,
  'aria-label': true,
  'aria-labelledby': true,
  'aria-level': true
};
export const domPropNames = {
  id: true,
  role: true,
  tabIndex: true,
  onMouseEnter: true,
  onMouseLeave: true
};
export const DOMPropNames = new Set(Object.keys(domPropNames));
export const linkPropNames = {
  download: true,
  href: true,
  ping: true,
  referrerPolicy: true,
  rel: true,
  target: true
};
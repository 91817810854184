import { ApolloCacheMetrics } from '../Metrics';
import BaseReporter from './BaseReporter';
// @ts-expect-error global property, populated by apollo-stack-hubspot
// By setting this property, apollo-stack-hubspot will start recording
// the execution time of the cache methods when they are called and
// accumulate the results on this object.
// https://git.hubteam.com/HubSpot/bender-repackages/blob/fb83b39d0f1b6112c2e0ce85ee4df91f9ac0c5f5/apollo-stack-hubspot/apollo-stack-hubspot-v3/repackages/overrides/%40apollo/client/cache/inmemory/inMemoryCache.js#L20
window.APOLLO_CACHE_EXECUTION_PROFILING = {};
export function reportApolloCacheMetrics(metrics) {
  for (const [key, value] of Object.entries(metrics)) {
    ApolloCacheMetrics.timer('method-execution-time', {
      method: key
    }).update(value);
  }
}
export class ApolloCacheMetricsReporter extends BaseReporter {
  report(action) {
    if (action.type === 'ROUTE_SUCCEEDED') {
      // @ts-expect-error global property, populated by apollo-stack-hubspot
      const profiling = window.APOLLO_CACHE_EXECUTION_PROFILING;
      if (profiling) {
        // @ts-expect-error global property, populated by apollo-stack-hubspot
        delete window.APOLLO_CACHE_EXECUTION_PROFILING;
        reportApolloCacheMetrics(profiling);
      }
    }
  }
}